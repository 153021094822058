// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "lazysizes";
import "lazysizes/plugins/bgset/ls.bgset";
import jQuery from "jquery"
window.$ = window.jQuery = jQuery

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$( document ).on('turbolinks:load', function(){
	$('.lang-block').on('click', '.lang-block__name', function () {
		const target = $(this);
		if (target.hasClass('lang-block__name')) {
			let url = this.dataset.url;
			if (!url) {
				const lang = this.dataset.lang;
				const loc = location.pathname;
				url = localeUrl(loc.replace(/^(?:\/[a-zA-Z]{2}(?=\/|$)|\/$|)/, ''), lang);
			}
			location = url + location.search + location.hash;
		}
	});

	$('.memobook__scroll').click(function(e) {
		const anchor = $(this).data('link');

		$('html').animate({
			scrollTop: $(anchor).offset().top
		}, 400);
	});
	$('a:not([href*="http"]):not([href*="mailto:"]):not([href^="/files/"]):not([href^="#"])').click(function(e) {
		e.preventDefault();
		const href = $(this).attr('href');
		if ($('.memobook').data('lang') === 'uk') {
			Turbolinks.visit('/uk' + href);
		} else {
			Turbolinks.visit('/en' + href);
		}
	});
});

function localeUrl(url, locale)	{
	locale = locale || language;
	return (('/' + locale) + url) || '/';
}
